import { useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {toast} from "react-toastify";
import LoadingComponent from "../Loading/LoadingComponent";

import PayFixedProduct from "./PayFixedProduct";
import PayFreeProduct from "./PayFreeProduct";
import PayDefaultProduct from "./PayDefaultProduct";
import {useAuthStore} from "../../store/user-store";

export default function ProductDetails() {

const authStore=useAuthStore()
    const product_id = useParams().id
    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState(null)

    const getProductDetails = async () => {
        setLoading(true)
        try {
            const response = await baseApi(`/products/${product_id}`)
            setProduct(response.data?.product)
        } catch (e) {
            toast.dark(e.response.data?.msg)
        }
        setLoading(false)
    }
    useEffect(() => {
        getProductDetails()
    }, [])

    if (loading) {
        return (
            <div className={'container mt-3'}>
                <div className="row justify-content-center">
                    <div className={'col-12 text-center'}>
                        <LoadingComponent/>
                    </div>
                </div>

            </div>
        )
    } else if (!loading && product === null) {
        return (
            <div className={'container mt-3'}>
                <div className="row justify-content-center">
                    <div className={'col-6 text-center'}>
                        <p className={'text-white bg-primary rounded py-2 fs-5 box-shadow'}>المنتج غير موجود </p>
                    </div>
                </div>

            </div>
        )
    } else {
        return (
            <div className={'container my-3'}>
                <div className="row my-2">
                    <div className="col-12">
                        <div className="d-flex gap-3 align-items-center">
                            <i className="fas fa-caret-left text-orange fa-2x"/>
                            <h4 className={'text-white'}>تأكيد عملية الشراء</h4>
                        </div>
                    </div>
                </div>
                <div className="row my-4 ">
                    <div className="col-md-3">
                        <div className={'d-flex flex-column align-items-center  justify-content-between' }>
                            <img className={'product-img'} loading={'lazy'} src={product.img} alt={product.name}/>
                            <p className={'text-white border border-1 border-orange p-4 my-2 rounded small'}>{product.info}</p>
                            <div className={'d-flex flex-row justify-content-around align-items-center'} style={{gap:'30px'}}>
                                <span className={'text-orange'}>{product.price} {authStore.icon? <i className="fa-solid fa-coins"></i>:'$'}</span>

                            </div>
                        </div>
                    </div>
                    {product.type==='default'?  <PayDefaultProduct product={product}/> :
                        (!product.is_free)?<PayFixedProduct product={product}/>:<PayFreeProduct product={product}/>}
                </div>

            </div>
        )
    }
}