import {Link, NavLink, useNavigate} from "react-router-dom";
import {useAuthStore} from "../../store/user-store";

import {useEffect} from "react";


export default function Layout({children}) {
    const auth_store = useAuthStore()
    const redirect = useNavigate()

    const logOut = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        auth_store.logOut()
        redirect('/')
    }
    useEffect(() => {
        console.log(window.location.pathname)
        document.title = auth_store.settings?.name || 'الصفحة الرئيسية'
        if (auth_store.settings?.logo) {
            const link = document.querySelector("link[rel~='icon']");
            if (link) {
                link.href = auth_store.settings.logo;
            }
        }
    }, [auth_store.settings])

    return (
        <>

            <section className={'bg-gradient-main-img section-wrapper'}>

                <nav className="navbar bg-gradient-main nav-h fixed-top border-botom-white box-shadow mb-4" dir={'ltr'}>
                    <div className="container-fluid">
                        <Link to={'/'} className="navbar-brand text-white">{auth_store.settings?.name}  </Link>
                        {auth_store.user ? <Link to={'/balances'} className="navbar-brand text-white"><span dir={'ltr'}
                                                                                                            className="text-orange d-inline-block mx-5">{auth_store.icon? <i className="fa-solid fa-coins"></i>:'$'} {auth_store.user?.balance.toFixed(2)} </span>
                        </Link> : null}
                        <button className="btn bg-secondary" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i
                            className={'fa fa-bars fa-lg text-white'}></i>
                        </button>
                    </div>

                </nav>

                {/* Start SideBar */}
                <div className="offcanvas offcanvas-end bg-secondary sidebar-width" tabIndex="-1" id="offcanvasRight"
                     aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header bg-secondary">
                        <div className="d-flex justify-content-between align-items-center flex-1 ">
                            <h5 className="offcanvas-title text-white flex-1"
                                id="offcanvasRightLabel"> {auth_store.user ? <span>
                            <img className={'group-img'} src={auth_store.user?.group_img} alt=""/>
                            <span>{auth_store.user?.name}</span> <span dir={'ltr'}
                                                                       className="text-orange">
                                  {auth_store.icon? <i className="fa-solid fa-coins"></i>:'$'}
                                     {auth_store.user?.balance.toFixed(2)} </span></span> :
                                <span>{auth_store.settings?.name}</span>}</h5>
                            <button type="button" className="bg-transparent border-0 text-white"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close">
                                <i className="fa fa-close"></i>
                            </button>
                        </div>
                    </div>
                    <div className="offcanvas-body bg-secondary">
                        <ul className={'nav flex-column'} data-bs-dismiss="offcanvas"
                            aria-label="Close">
                            <li className={'nav-item '}>

                                <Link className={'nav-link  text-white'} to={'/'}> <i
                                    className="fa fa-home text-orange mx-2"/>الرئيسية</Link>
                            </li>
                            {auth_store.user !== null ? <li className={'nav-item my-2'}>
                                <Link className={'nav-link  text-white'} to={'/profile'}><i
                                    className="fa fa-basket-shopping text-orange mx-2"/>الملف الشخصي</Link>
                            </li> : null}
                            {auth_store.user === null ? <li className={'nav-item my-2'}>
                                <Link className={'nav-link  text-white'} to={'/login'}><i
                                    className="fa fa-right-to-bracket text-orange mx-2"/>تسجيل الدخول</Link>
                            </li> : null}
                            {auth_store.user === null ? <li className={'nav-item my-2'}>
                                <Link className={'nav-link  text-white'} to={'/register'}><i
                                    className="fa fa-id-card text-orange mx-2"/>تسجيل جديد</Link>
                            </li> : null}


                            {auth_store.user !== null ? <li className={'nav-item my-2'}>
                                <Link className={'nav-link  text-white'} to={'/orders'}><i
                                    className="fa fa-basket-shopping text-orange mx-2"/>المشتريات</Link>
                            </li> : null}


                            {auth_store.user !== null ? <li className={'nav-item my-2'}>
                                <Link className={'nav-link  text-white'} to={'/balances'}><i
                                    className="fa fa-dollar-sign text-orange mx-2"/>حركة الرصيد</Link>
                            </li> : null}
                            <li className={'nav-item my-2 border border-1 border-white p-1'}>
                                <a className={'nav-link  text-white '} href={`https://wa.me/${auth_store.settings?.whatsapp}`} target={'_blank'}><i
                                    className="fa-brands fa-whatsapp whatsapp-icon fa-xl mx-2 small"/>لشحن الرصيد تواصل مع الإدارة </a>
                            </li>

                            {auth_store.user != null ? <li className={'nav-item w-100 my-2'} onClick={logOut}>
                                <span className={' nav-link text-white'}><i
                                    className="fa fa-right-from-bracket text-orange mx-2"/>خروج</span>
                            </li> : null}

                            <li className={'nav-item my-2 border-1 border-top pt-2 gap-2 d-flex'}>
                                {/*<a className={'nav-link  text-white'} target={'_blank'} rel="noopener noreferrer" href={`https://wa.me/${auth_store.settings?.whatsapp}`}>
                                    <i className="fa-brands fa-whatsapp  text-orange fa-lg mx-2" />تواصل مع الإدارة</a>*/}

                                <a href={auth_store.settings?.facebook} target={'_blank'}>
                                    <i className="fa-brands fa-facebook fa-xl facebook-icon"/>
                                </a>
                                <a href={auth_store.settings?.telegram} target={'_blank'}>
                                    <i className="fa-brands fa-telegram fa-xl telegram-icon"/>
                                </a>
                                <a href={auth_store.settings?.instagram} target={'_blank'}>
                                    <i className="fa-brands fa-instagram fa-xl instagram-icon"/>
                                </a>
                                <a href={`https://wa.me/${auth_store.settings?.whatsapp}`} target={'_blank'}>
                                    <i className="fa-brands fa-whatsapp whatsapp-icon fa-xl"/>
                                </a>

                            </li>
                        </ul>
                    </div>
                </div>
                {/* End SideBar */}
                <div className={'wrapper min-100-vh'}>
                    {children}
                </div>


                {/*   <footer className={'bg-gradient-main border-top-white footer'} >
                    <div className="container-fluid">
                        <div className="row justify-content-center align=items-center">
                            <div className="col-6">
                                <p className={'text-white my-3'}> جميع الحقوق محفوظة لموقع <span className={'text-orange'}>{auth_store.settings?.name}</span></p>
                            </div>
                            <div className="col-6">
                                <div className="d-flex justify-content-center align-items-center gap-2 my-3">

                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
            </section>
            <footer className={'bottom-nav py-2 d-block d-md-none'}>
                <div className="d-flex justify-content-around align-items-center">

                    <NavLink to={'/balances'}><i className="fa fa-dollar-sign fa-lg text-white"></i></NavLink>
                    <NavLink to={'/'}><i className="fa fa-home fa-lg text-white"></i></NavLink>
                    <NavLink to={'/orders'}><i className="fa fa-shopping-basket fa-lg text-white"></i></NavLink>
                </div>
            </footer>

        </>

    );
}
