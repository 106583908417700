import {create} from "zustand";

export const useAuthStore = create((set) => {
    return {
        icon:false,
        user:null,
        settings:null,
        setUser: ( user) => {
            set({user})

        },
        logOut: () => {
            set({user: null})

        },
        setSettings: ( settings) => {
            set({settings})

        },
    }
})